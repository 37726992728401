/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: collaboratif-webapp-contrib
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Fonctions JavaScript spécifiques à l'arbre des dossiers.
 * Nécessite la déclaration d'un jquery propre pour l'utilisation des dialogs
 */
(function (jqueryDialog) {
    'use strict';
    var $tree;

    /**
     * Fonction permettant de déterminer si le noeud fournit doit
     * afficher l'action créée.
     * @param {String} nodeId l'id du noeud en cours de rendu.
     * @returns {boolean} true si le noeud doit afficher l'action, false sinon
     * @private
     */
    function rootFilter(nodeId) {
        return nodeId !== 'root';
    }

    /**
     * Gère la suppression d'un noeud
     * @param node_id l'id du noeud
     * @param node le noeud
     */
    function handlDelete(node_id, node) {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            $tree.refresh();
        };
        xhr.open('DELETE', node.original.deleteUrl, true);
        xhr.send();
    }

    /**
     * Ouverture de la popin d'ajout de dossier
     * @param node_id l'id du noeud à partir duquel la popin est ouverte
     * @param node le noeud à partir duquel la popin est ouverte
     */
    function openAddPopin(node_id, node) {
        var folderName = jqueryDialog('input#js-folder-name');
        var dialog = jqueryDialog('div#js-popin')
            .dialog({
                buttons: [{
                    text: LOCALE_BO.collaboratif.tree.valider,
                    click: function () {
                        var xhr = new XMLHttpRequest();
                        var params = 'libelle=' + folderName.val() + '&parent=' + node.li_attr.code + '&space=' + node.li_attr.space;
                        xhr.open('POST', node.original.createUrl, true);
                        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        xhr.addEventListener('load', function() {
                            dialog.dialog('close');
                            $tree.refresh();
                        }, false);
                        xhr.send(params);
                    }
                }, {
                    text: LOCALE_BO.collaboratif.tree.annuler,
                    click: function () {
                        jqueryDialog(this).dialog('close');
                    }
                }],
                width: 400,
                close: function() {
                    folderName.val('');
                },
                title: LOCALE_BO.collaboratif.tree.popinAddTitle
            });
    }

    /**
     * Ouverture de la popin d'ajout de dossier
     * @param node_id l'id du noeud à partir duquel la popin est ouverte
     * @param node le noeud à partir duquel la popin est ouverte
     */
    function openEditPopin(node_id, node) {
        var folderName = jqueryDialog('input#js-folder-name');
        folderName.val(node.text);
        var dialog = jqueryDialog('div#js-popin')
            .dialog({
                buttons: [{
                    text: LOCALE_BO.collaboratif.tree.valider,
                    click: function () {
                        var xhr = new XMLHttpRequest();
                        var params = 'libelle=' + folderName.val() + '&code=' + node.li_attr.code;
                        xhr.open('POST', node.original.editUrl, true);
                        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        xhr.onreadystatechange = function() {
                            dialog.dialog('close');
                            $tree.refresh();
                        };
                        xhr.send(params);
                    }
                }, {
                    text: LOCALE_BO.collaboratif.tree.annuler,
                    click: function () {
                        jqueryDialog(this).dialog('close');
                    }
                }],
                width: 400,
                close: function() {
                    folderName.val('');
                },
                title: LOCALE_BO.collaboratif.tree.popinModifyTitle
            });
    }

    /**
     * Fonction utilisée pour ajouter les différentes actions contextuelles sur
     * les noeuds de l'arborescence.
     * @param {Object} $tree l'instance d'arboresence jQuery.
     * @rpivate
     */
    function addActions($tree) {
        $tree.add_action(
            'all', {
                'id': 'action_add',
                'class': 'btn btn--as-link btn--sm pull-right js-action_add ',
                'text': LOCALE_BO.collaboratif.tree.add,
                'after': true,
                'selector': 'a',
                'event': 'click',
                'callback': openAddPopin
            });
        $tree.add_action(
            'all', {
                'id': 'action_edit',
                'class': 'btn btn--as-link btn--sm pull-right js-action_edit ',
                'text': LOCALE_BO.collaboratif.tree.edit,
                'after': true,
                'selector': 'a',
                'event': 'click',
                'filter': rootFilter,
                'callback': openEditPopin
            });
        $tree.add_action(
            'all', {
                'id': 'action_delete',
                'class': 'btn btn--as-link btn--sm pull-right js-action_delete ',
                'text': LOCALE_BO.collaboratif.tree.delete,
                'after': true,
                'selector': 'a',
                'event': 'click',
                'filter': rootFilter,
                'callback': handlDelete
            });
    }

    /**
     * Chargement de l'arbre dans le cas où on est sur le bon écran.
     * En fonction du temps de chargement des scripts js, deux solutions sont possible :
     *  - L'arbre est déjà chargé, on le récupère pour ajouter les actions
     *  - L'arbre n'est pas chargé, on ajoute un écouteur sur le chargement pour savoir quand il va être effectué
     */
    if (document.getElementById('js-collab-folder')) {
        var tree = $('#folderTreeview .jstree');
        if (tree.jstree(true)) {
            $tree = tree.jstree(true);
            addActions($tree);
        } else {
            document.addEventListener(
                'treeview:loaded', function handleTreeLoad(e) {
                    $tree = e.detail.tree;
                    addActions($tree);
                }, true);
        }
    }
})(jQuery.noConflict());
