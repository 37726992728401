/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: arbre-webapp-legacy
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function() {
    'use strict';
    document.addEventListener('DOMContentLoaded', function() {
        /**
         * Mise à jour de l'affichage de l'arbre, en fonction du hash de la page
         */
        function updateArbreDisplay() {
            if (window.location.hash && /^#arbre-/.test(window.location.hash)) {
                var arbres = document.querySelectorAll('.tag-arbre');
                // On efface masque tous les éléments ouverts
                [].forEach.call(arbres, function(arbre) {
                    [].forEach.call(arbre.querySelectorAll('.tag-arbre__liste--selected'), function(selected) {
                        selected.classList.remove('tag-arbre__liste--selected');
                    });
                    [].forEach.call(arbre.querySelectorAll('.tag-arbre__element--displayed'), function(displayed) {
                        displayed.classList.remove('tag-arbre__element--displayed');
                    });
                });
                // On récupère la destination à afficher (si elle existe)
                var target = document.querySelector(window.location.hash);
                if (target != null) {
                    var leaf = target.parentNode;
                    // On affiche la destination ainsi que tous ses parents
                    do {
                        leaf.classList.add('tag-arbre__element--displayed');
                        leaf.parentNode.classList.add('tag-arbre__liste--selected');
                        leaf = leaf.parentNode.parentNode;
                    } while (leaf.classList.contains('tag-arbre__element'));
                }
            }
        }

        // Bind des événements sur les arbres
        var arbres = document.querySelectorAll('.tag-arbre');
        [].forEach.call(arbres, function(arbre) {
            var buttons = arbre.querySelectorAll('a');
            [].forEach.call(buttons, function(button) {
                button.addEventListener('click', function() {
                    var li = button.parentNode;
                    var ul = li.parentNode;
                    // Affichage ou non des enfants au clic
                    if (ul.classList.contains('tag-arbre__liste--selected') || ul.getAttribute('data-profondeur') === '0') {
                        ul.classList.remove('tag-arbre__liste--selected');
                        li.classList.remove('tag-arbre__element--displayed');
                        // On masque tous les enfants si on fait un repliage de plus d'un niveau
                        var childrenUl = li.querySelectorAll('ul.tag-arbre__liste--selected');
                        [].forEach.call(childrenUl, function(childUl) {
                            childUl.classList.remove('tag-arbre__liste--selected');
                            var childrenLi = childUl.querySelectorAll('li.tag-arbre__element--displayed');
                            [].forEach.call(childrenLi, function(childLi) {
                                childLi.classList.remove('tag-arbre__element--displayed');
                            });
                        });
                    } else {
                        ul.classList.add('tag-arbre__liste--selected');
                        li.classList.add('tag-arbre__element--displayed');
                    }
                });
            });

            // Quand la page se charge, on appelle la fonction de mise à jour de l'affichage de l'arbre pour pouvoir l'initialiser dans un état défini par un éventuel hash.
            updateArbreDisplay();
        });

        // On écoute les changements sur l'ancre pour gérer, notamment, le précédent/suivant du navigateur et ainsi naviguer dans les ouvertures/fermetures successives de l'arbre
        window.addEventListener('hashchange', updateArbreDisplay);
    });
})();
