/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: lieu-webapp-contrib
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function($, window) {
    $.fn.kGoogleMap = function() {
        var infoWindows = [];
        var markerToDisplay = null;
        var bounds = new google.maps.LatLngBounds();

        var addMarkerToMap = function(map, dom, o, openByDefault) {
            var geocoder = new google.maps.Geocoder();
            o.map = map;

            var popupHeader = o.popupHeader;
            var popupBody = o.popupBody;
            delete o['popupHeader'];
            delete o['popupBody'];

            var a = document.querySelector('[data-marker-uniqid="' + o.markerUniqId + '"] .route-link').outerHTML;

            var infoWindow = new google.maps.InfoWindow({
                content: '<address><header><h1>' + popupHeader + '</h1></header><div class="content">' + popupBody + '</div><footer>' + a + '</footer></address>',
                maxWidth: 600
            });
            infoWindows.push(infoWindow);

            var latitude = o.latitude;
            var longitude = o.longitude;
            var address = o.address;
            delete o['latitude'];
            delete o['longitude'];
            delete o['address'];

            var uniqId = o.markerUniqId;
            delete o['markerUniqId'];

            var finallyAddMarker = function(marker) {
                marker = new google.maps.Marker(marker);

                var triggerInfoWindow = function() {
                    for (var i = 0; i < infoWindows.length; ++i) {
                        infoWindows[i].close();
                    }
                    infoWindow.open(map, marker);
                };

                google.maps.event.addDomListener(marker, 'click', triggerInfoWindow);
                $('[data-marker-uniqid="' + uniqId + '"]').click(triggerInfoWindow);

                bounds.extend(marker.position);

                if (openByDefault && markerToDisplay === null) {
                    markerToDisplay = {
                        infoWindow: infoWindow,
                        marker: marker
                    };
                }
            };

            if (latitude != null && longitude != null) {
                o.position = new google.maps.LatLng(latitude, longitude);
                finallyAddMarker(o);
            } else {
                geocoder.geocode({'address': address}, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        o.position = results[0].geometry.location;
                        finallyAddMarker(o);
                    }
                });
            }
        };

        var addMarkersToMap = function(map, dom, a) {
            var openByDefault = (a.length === 1);
            for (var i = 0; i < a.length; ++i) {
                addMarkerToMap(map, dom, a[i], openByDefault);
            }
        };

        var addMap = function(o) {
            var dom = o.dom.find(".map-canvas")[0];
            delete o['dom'];
            return new google.maps.Map(dom, o);
        };

        var createMap = function(o, a) {
            var map = addMap(o);
            addMarkersToMap(map, o.dom, a);
            window.setTimeout(function(){
                if (a.length > 1) {
                    map.fitBounds(bounds);
                }
                if (markerToDisplay !== null) {
                    markerToDisplay.infoWindow.open(map, markerToDisplay.marker);
                }
            }, 3000); // timeout to prevent infoWindow and markers displaying out of the frame
        };

        var searchFirstMarker = function(jsonMarkers) {
            var firstMarker = {
                latitude: null,
                longitude: null,
                address: null,
                isSet: false
            };

            for (var i = 0; i < jsonMarkers.length && firstMarker.isSet === false; ++i) {
                var currentMarker = jsonMarkers[i];
                if ((currentMarker.latitude != null && currentMarker.longitude != null) || currentMarker.address != null) {
                    firstMarker.latitude = currentMarker.latitude;
                    firstMarker.longitude = currentMarker.longitude;
                    firstMarker.address = currentMarker.address;
                    firstMarker.isSet = true;
                }
            }

            return firstMarker;
        };

        return this.each(function() {
            var $t = $(this);
            var jsonMarkers = JSON.parse($t.attr("data-map-markers"));
            var firstMarker = searchFirstMarker(jsonMarkers);
            if (firstMarker.latitude != null && firstMarker.longitude != null) {
                createMap({
                    dom: $t,
                    center: new google.maps.LatLng(firstMarker.latitude, firstMarker.longitude),
                    zoom: 14,
                    zoomControl: true,
                    streetViewControl: false
                }, jsonMarkers);
            } else if (firstMarker.address != null) {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({'address': firstMarker.address}, function(results) {
                    createMap({
                        dom: $t,
                        center: results[0].geometry.location,
                        zoom: 14,
                        zoomControl: true,
                        streetViewControl: false
                    }, jsonMarkers);
                });
            } else {
                createMap({
                    dom: $t,
                    center: new google.maps.LatLng(0, 0),
                    zoom: 1,
                    zoomControl: true,
                    streetViewControl: false
                }, jsonMarkers);
            }
            $t.find('footer dl dt').hide();
            $t.find('footer dl dd>span').hide();
        });
    };
})(jQuery, window);
