/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: collaboratif-webapp-contrib
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
jQuery.extend(
    true, window.LOCALE_BO, {
        collaboratif: {
            refusalTitle: "Refuser",
            confirmSupprMembreEspace: "Vous êtes sur le point de supprimer un membre de l'espace. Êtes-vous sûr ?",
            tree: {
                add: "Ajouter",
                delete: "Supprimer",
                edit: "Modifier",
                popinAddTitle: "Ajout d'un dossier",
                popinModifyTitle: "Modification d'un dossier",
                valider: "Valider",
                annuler: "Annuler"
            }
        }
    });
