/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: collaboratif-webapp-contrib
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function ($) {
    var treeReady = true;
    var creationMode = false;
    // Gestion de l'arborescence
    var $kTree = $('.kTree'),
        cancel = {position: -1};

    function _isInDatas(a, word) {
        var inDatas = false;
        if (a) {
            var $item = $(a).closest('li');
            $.each(
                $item.data(), function (index, value) {
                    if (value.toLowerCase().indexOf(word) > -1) {
                        inDatas = true;
                        return false;
                    }
                });
        }
        return inDatas;
    }

    $kTree.each(
        function (index, component) {
            var $component = $(component),
                dataUrl = $component.data('url'),
                dnd = $component.data('dnd') ? $component.data('dnd') : false,
                actions = $component.data('actions') ? $component.data('actions') : false,
                codeEspaceCourant = $('#codeEspaceCourant').val();
            // Only enable checkboxes in multiselect cases
            var plugins = ["themes", "ui", "json_data", "types"];
            if (dnd) {
                plugins.push("dnd");
                plugins.push("crrm");
            }
            if (actions) {
                plugins.push("actions");
            }
            // Only way to not trigger the 'select_node.jstree' event on load with the param 'initially_select' for 'ui' plugin
            $component.bind(
                "reselect.jstree", function () {
                    treeReady = true;
                }).jstree(
                {
                    core: {
                        animation: 300,
                        html_titles: true
                    },
                    "json_data": {
                        "ajax": {
                            "url": function (node) {
                                if (node === -1) {
                                    return $.parametizeString(dataUrl, [codeEspaceCourant]);
                                } else {
                                    return $.parametizeString(dataUrl, [node.data('sCode')]);
                                }
                            },
                            "type": "get",  // this is a GET not a POST
                            "success": function (ops) {
                                if (ops[0]) {
                                    if (ops[0].attr.rel == "root") {
                                        return ops;
                                    }
                                    return ops[0].children;
                                }
                            },
                            "progressive_render": true,
                            "progressive_unload": true
                        }
                    },
                    "themes": {
                        "theme": "ksup",
                        "url": "/adminsite/scripts/libs/css/jstree-1.0/themes/ksup/style.css",
                        "dots": true,
                        "icons": true
                    },
                    "ui": {
                        "select_limit": 1
                    },
                    "types": {
                        "valid_children": ["root"],
                        "types": {
                            "root": {
                                "valid_children": ["default"]
                            },
                            "default": {
                                "valid_children": ["default"]
                            },
                            "not_selectable": {
                                "check_node": false,
                                "uncheck_node": false,
                                "hover_node": false,
                                "select_node": function () {
                                    return false;
                                }
                            }
                        }
                    },
                    "crrm": {
                        "move": {
                            "check_move": function (m) {
                                return true;
                            }
                        }
                    },
                    "dnd": {
                        "drop_target": false,
                        "drag_target": false
                    },
                    "actions": {
                        filter: function () {
                            return true;
                        },
                        buttons: {
                            'default': [{
                                label: 'Ajouter',
                                className: 'ajouter',
                                filter: function () {
                                    return true;
                                },
                                binding: {
                                    'click': function (event) {
                                        var $node = $(this).closest('li');
                                        $kTree.jstree('create', $node, 'last', {data: 'Nouveau dossier'}, null, false);
                                    }
                                }
                            },
                                {
                                    label: '&Eacute;diter',
                                    className: 'editer',
                                    filter: function (element) {
                                        return !$(element).is('.dossiergw_root');
                                    },
                                    binding: {
                                        'click': function (event) {
                                            var $node = $(this).closest('li');
                                            $node.data('libelleAvantRenommage', $node.data('libelle'));
                                            $('.jstree-actions', $node).remove();
                                            $kTree.jstree("rename", $node);
                                        }
                                    }
                                },
                                {
                                    label: "Supprimer",
                                    className: 'supprimer',
                                    filter: function (element) {
                                        return !$(element).is('.dossiergw_root');
                                    },
                                    binding: {
                                        'click': function (event) {
                                            var $node = $(this).closest('li');
                                            tree = $.jstree._reference('.kTree');
                                            deleteFolder(event, $node, tree);
                                        }
                                    }
                                }]
                        }
                    },
                    "plugins": plugins
                });
        });
    $kTree.bind(
        "select_node.jstree", function (event, data) {
            var $node = data.rslt.obj,
                $messageApplicatif = $('#messageApplicatif'),
                $contenuDossier = $('.contenuDossier'),
                $p = $('<p>').addClass('message alert fade in'),
                $closeButton = $('<button>').addClass('close').attr(
                    {
                        'type': 'button',
                        'data-dismiss': 'alert',
                        'aria-hidden': true
                    }).html('&times;');
            $.ajax(
                {
                    type: "POST",
                    url: '/servlet/com.kportal.servlet.JsTreeServlet',
                    dataType: 'json',
                    data: {
                        'JSTREEBEAN': 'dossierGwJsTree',
                        'ACTION': 'AFFICHER_FICHES',
                        'CODES_DOSSIERGW': [$node.data('sCode')],
                        'CODE_ESPACE_COURANT': $('#codeEspaceCourant').val()
                    },
                    success: function (data, status) {
                        var htmlContenu = '';
                        htmlContenu = '<ul>';
                        $.each(
                            data, function (i, item) {
                                htmlContenu = htmlContenu + '<li>';
                                htmlContenu = htmlContenu + '<a href="';
                                htmlContenu = htmlContenu + item.url;
                                htmlContenu = htmlContenu + '" class="';
                                htmlContenu = htmlContenu + item.cssClass;
                                htmlContenu = htmlContenu + '">';
                                htmlContenu = htmlContenu + item.libelle;
                                htmlContenu = htmlContenu + '</a>';
                                htmlContenu = htmlContenu + '</li>';
                            });
                        htmlContenu = htmlContenu + '</ul>';
                        $contenuDossier.html(htmlContenu);
                        // affiche le contenu du dossier
                        $('#contenuDossier').show();
                    },
                    error: function (jqXHR, status, error) {
                        var message = jqXHR.responseText ? jqXHR.responseText : LOCALE_BO.services.arbre.indisponible;
                        $p.addClass('alert-danger');
                        $p.html(message);
                        $p.appendTo($messageApplicatif);
                        $closeButton.appendTo($p);
                        // cache le contenu du dossier
                        $('#contenuDossier').hide();
                    }
                });
        });
    $(document).bind(
        "drag_start.vakata", function (e, data) {
            var $node = data.data.obj;
            cancel = {
                node: $node,
                parent: $.jstree._reference('.kTree')._get_parent($node),
                position: $node.index()
            };
        });
    function cancelMove() {
        if (cancel && cancel.node && cancel.parent && cancel.position != -1) {
            $.jstree._reference('.kTree').move_node(cancel.node, cancel.parent, cancel.position);
            cancel = {position: -1};
        }
    }

    $kTree.bind(
        "move_node.jstree", function (event, data) {
            var $node = data.rslt.o,
                codeMere = data.rslt.np.data('sCode'),
                $messageApplicatif = $('#messageApplicatif'),
                $p = $('<p>').addClass('message alert fade in'),
                $closeButton = $('<button>').addClass('close').attr(
                    {
                        'type': 'button',
                        'data-dismiss': 'alert',
                        'aria-hidden': true
                    }).html('&times;');
            $.ajax(
                {
                    type: "POST",
                    url: '/servlet/com.kportal.servlet.JsTreeServlet',
                    data: {
                        'JSTREEBEAN': 'dossierGwJsTree',
                        'ACTION': 'DEPLACER',
                        'CODES_DOSSIERGW': [$node.data('sCode')],
                        'CODES_MERE': [codeMere],
                        'ORDRES': [$node.index()],
                        'CODE_ESPACE_COURANT': $('#codeEspaceCourant').val()
                    },
                    success: function (data, status) {
                        $p.addClass('alert-success');
                        $p.html(data);
                        $p.appendTo($messageApplicatif);
                        $closeButton.appendTo($p);
                        $node.transition(
                            {
                                'background-color': '#eda619',
                                duration: 500
                            }, function () {
                                $node.transition(
                                    {
                                        'background-color': '#fff',
                                        delay: 500
                                    });
                            });
                    },
                    error: function (jqXHR, status, error) {
                        var message = jqXHR.responseText ? jqXHR.responseText : LOCALE_BO.services.arbre.indisponible;
                        $p.addClass('alert-danger');
                        $p.html(message);
                        $p.appendTo($messageApplicatif);
                        $closeButton.appendTo($p);
                        cancelMove();
                    }
                });
        });
    $kTree.bind(
        "rename_node.jstree", function (event, data) {
            if (creationMode) {
                handleCreateNode(event, data);
            } else {
                handleRenameNode(event, data);
            }
        });
    function handleRenameNode(event, data) {
        var $node = data.args[0],
            nouveauNom = data.rslt.name,
            $messageApplicatif = $('#messageApplicatif'),
            $p = $('<p>').addClass('message alert fade in'),
            $closeButton = $('<button>').addClass('close').attr(
                {
                    'type': 'button',
                    'data-dismiss': 'alert',
                    'aria-hidden': true
                }).html('&times;');
        // test de l'element root, impossible de le renommer
        if ($node.attr('rel') == 'root') {
            var message = 'Impossible de renommer le dossier racine';
            $p.addClass('alert-danger');
            $p.html(message);
            $p.appendTo($messageApplicatif);
            $closeButton.appendTo($p);
            // retablissement de l'ancien nom
            $node.data('libelle', $node.data('libelleAvantRenommage'));
            $node.attr('title', $node.data('libelleAvantRenommage'));
            $node.find('>a:first').html($node.data('libelleAvantRenommage'))
        } else {
            $.ajax(
                {
                    type: "POST",
                    url: '/servlet/com.kportal.servlet.JsTreeServlet',
                    data: {
                        'JSTREEBEAN': 'dossierGwJsTree',
                        'ACTION': 'EDITER',
                        'CODES_DOSSIERGW': [$node.data('sCode')],
                        'NOUVEAU_NOM_DOSSIER': [nouveauNom],
                        'CODE_ESPACE_COURANT': $('#codeEspaceCourant').val()
                    },
                    success: function (data, status) {
                        $p.addClass('alert-success');
                        $p.html(data);
                        $p.appendTo($messageApplicatif);
                        $closeButton.appendTo($p);
                        $node.transition(
                            {
                                'background-color': '#eda619',
                                duration: 500
                            }, function () {
                                $node.transition(
                                    {
                                        'background-color': '#fff',
                                        delay: 500
                                    });
                            });
                    },
                    error: function (jqXHR, status, error) {
                        var message = jqXHR.responseText ? jqXHR.responseText : LOCALE_BO.services.arbre.indisponible;
                        $p.addClass('alert-danger');
                        $p.html(message);
                        $p.appendTo($messageApplicatif);
                        $closeButton.appendTo($p);
                        // retablissement de l'ancien nom
                        $node.data('libelle', $node.data('libelleAvantRenommage'));
                        $node.html($node.data('libelleAvantRenommage'));
                    }
                });
        }
    }

    $kTree.bind(
        "create_node.jstree", function (event, data) {
            creationMode = true;
        });
    function handleCreateNode(event, data) {
        var $node = data.args[0],
            nouveauNom = data.rslt.name,
            $messageApplicatif = $('#messageApplicatif'),
            $p = $('<p>').addClass('message alert fade in'),
            $closeButton = $('<button>').addClass('close').attr(
                {
                    'type': 'button',
                    'data-dismiss': 'alert',
                    'aria-hidden': true
                }).html('&times;');
        $.ajax(
            {
                type: "POST",
                url: '/servlet/com.kportal.servlet.JsTreeServlet',
                data: {
                    'JSTREEBEAN': 'dossierGwJsTree',
                    'ACTION': 'AJOUTER',
                    'CODES_DOSSIERGW': [$node.parent().closest('li').data('sCode')],
                    'NOMS_DOSSIERGW': [nouveauNom],
                    'CODE_ESPACE_COURANT': $('#codeEspaceCourant').val()
                },
                success: function (data, status) {
                    var result = JSON.parse(data);
                    $p.addClass('alert-success');
                    $p.html(result.message);
                    $node.data('sCode', result.code);
                    $p.appendTo($messageApplicatif);
                    $closeButton.appendTo($p);
                    $node.transition(
                        {
                            'background-color': '#eda619',
                            duration: 500
                        }, function () {
                            $node.transition(
                                {
                                    'background-color': '#fff',
                                    delay: 500
                                });
                        });
                    creationMode = false;
                },
                error: function (jqXHR, status, error) {
                    var message = jqXHR.responseText ? jqXHR.responseText : LOCALE_BO.services.arbre.indisponible;
                    $p.addClass('alert-danger');
                    $p.html(message);
                    $p.appendTo($messageApplicatif);
                    $closeButton.appendTo($p);
                    creationMode = false;
                }
            });
    }

    // Suppression de l'élément
    function deleteFolder(event, node, tree) {
        var $ = jQuery,
            $messageApplicatif = $('#messageApplicatif'),
            $p = $('<p>').addClass('message alert fade in'),
            $closeButton = $('<button>').addClass('close').attr(
                {
                    'type': 'button',
                    'data-dismiss': 'alert',
                    'aria-hidden': true
                }).html('&times;');
        $.ajax(
            {
                type: "POST",
                url: '/servlet/com.kportal.servlet.JsTreeServlet',
                data: {
                    'JSTREEBEAN': 'dossierGwJsTree',
                    'ACTION': 'SUPPRIMER',
                    'CODES_DOSSIERGW': [node.data('sCode')],
                    'CODE_ESPACE_COURANT': $('#codeEspaceCourant').val()
                },
                success: function (data, status) {
                    $p.addClass('alert-success');
                    $p.html(data);
                    $p.appendTo($messageApplicatif);
                    $closeButton.appendTo($p);
                    node.transition(
                        {opacity: 0}, function () {
                            tree.delete_node(node);
                        });
                },
                error: function (jqXHR, status, error) {
                    $p.addClass('alert-danger');
                    $p.html(jqXHR.responseText);
                    $p.appendTo($messageApplicatif);
                    $closeButton.appendTo($p);
                }
            });
    }

    var $submitMedia = $('.js-collab-media');
    $submitMedia.click(
        function (e) {
            e.preventDefault();
            var $this = $(this);
            var popin = $.iframePopin(
                {
                    title: $this.html(),
                    url: $this.attr('href'),
                    autoOpen: true,
                    resizable: true,
                    width: 800,
                    onClose: function (iframe) {
                        this.destroy();
                    },
                    buttons: {
                        cancel: {
                            title: LOCALE_BO.annuler,
                            callback: function (iframe) {
                                this.destroy();
                            }
                        }
                    },
                    onClose: function () {
                        this.destroy();
                    }
                });
            var registeredId = iFrameHelper.registeriFrame(
                {
                    onAbort: function () {
                        popin.destroy();
                        location.reload(true);
                        iFrameHelper.unregisteriFrame(registeredId);
                    },
                    iFrame: popin.iFrame,
                    caller: $submitMedia
                });
        });
    var $submitVerrou = $('.js-collab-verrou');
    $submitVerrou.click(
        function (e) {
            e.preventDefault();
            var $this = $(this);
            var popin = $.iframePopin(
                {
                    title: $this.html(),
                    url: $this.attr('href'),
                    autoOpen: true,
                    resizable: true,
                    width: 600,
                    onClose: function (iframe) {
                        this.destroy();
                    },
                    buttons: {
                        cancel: {
                            title: LOCALE_BO.annuler,
                            callback: function (iframe) {
                                this.destroy();
                            }
                        }
                    },
                    onClose: function () {
                        this.destroy();
                    }
                });
            var registeredId = iFrameHelper.registeriFrame(
                {
                    onSendValues: function (object) {
                        $.get(
                            object.url, function () {
                            }).always(
                            function () {
                                popin.destroy();
                                location.reload(true);
                                iFrameHelper.unregisteriFrame(registeredId);
                            });
                    },
                    iFrame: popin.iFrame,
                    caller: $submitVerrou
                });
        });
    // ajouter membre (gestion_membre_liste.jsp)
    $('.js-ajouter').click(
        function (e) {
            e.preventDefault();
            var $this = $(this);
            $('[name="ACTION"]').val($this.attr('data-action'));
            $('#form_saisie_front').submit();
        });
    // supprimer membre (gestion_membre_liste.jsp)
    $('.js-supprimer').click(
        function (e) {
            e.preventDefault();
            var $this = $(this);
            $('<div>')
                .html(LOCALE_BO.collaboratif.confirmSupprMembreEspace)
                .dialog(
                {
                    title: LOCALE_BO.confirmer,
                    buttons: [{
                        text: LOCALE_BO.ok,
                        click: function () {
                            $(this).dialog('close');
                            $('[name="ACTION"]').val($this.attr('data-action'));
                            $('[name="CODE_MEMBRE"]').val($this.attr('data-code-membre'));
                            $('#form_saisie_front').submit();
                        }
                    }, {
                        text: LOCALE_BO.fermer,
                        click: function () {
                            $(this).dialog('close');
                        }
                    }]
                });
        });
    // accepter inscription (inscription_espace_liste_inscription.jsp)
    $('.js-accepter').click(
        function (e) {
            e.preventDefault();
            var $this = $(this);
            $('[name="ACTION"]').val($this.attr('data-action'));
            $('[name="CODE_MEMBRE"]').val($this.attr('data-code-membre'));
            $('#form_saisie_front').submit();
        });
    // refuser inscription (inscription_espace_liste_inscription.jsp)
    $('.js-refuser').click(
        function (e) {
            e.preventDefault();
            var $this = $(this);
            $('.js-refusal__form').dialog(
                {
                    title: LOCALE_BO.collaboratif.refusalTitle,
                    buttons: [{
                        text: LOCALE_BO.valider,
                        click: function () {
                            $(this).dialog('close');
                            $('[name="ACTION"]').val($this.attr('data-action'));
                            $('[name="CODE_MEMBRE"]').val($this.attr('data-code-membre'));
                            $('[name="MOTIF_REFUS"]').val($('#motif-refus-' + $this.attr('data-code-membre')).val());
                            $('#form_saisie_front').submit();
                        }
                    }]
                });
        });
})(jQuery.noConflict());